import * as React from "react";
import { useState } from "react";
import PackagesContext from "./PackagesContext";

const PackagesState = (props) => {
  const [packageValue, setPackageValue] = useState([]);
  const [formvalue, setformvalue] = useState([]);

  const [modal, setModal] = useState(false); //ADD DATA MODEL -->

  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [totalRecord, setTotalRecord] = useState();
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [gst, setGst] = useState("");
  const [qty, setQty] = useState("");
  const [type, setType] = useState("");
  const [orgId, setOrgId] = useState("");
  const [benefit, setBenefit] = useState("");
  const [packageId, setPackageID] = useState("");

  const [progress, setProgress] = useState(0);
  const [editIndex, seteditIndex] = useState(-1);
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [toggleSubmit, setToggleSubmit] = useState(false);

  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);
  const [isHovering6, setIsHovering6] = useState(false);
  return (
    <>
      <PackagesContext.Provider
        value={{
          packageValue,
          setPackageValue,
          formvalue,
          setformvalue,
          modal,
          setModal,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          name,
          setName,
          price,
          setPrice,
          gst,
          setGst,
          qty,
          setQty,
          type,
          setType,
          orgId,
          setOrgId,
          benefit,
          setBenefit,
          packageId,
          setPackageID,
          progress,
          setProgress,
          editIndex,
          seteditIndex,
          isHovering,
          setIsHovering,
          toggleSubmit,
          setToggleSubmit,
          isHovering1,
          setIsHovering1,
          isHovering2,
          setIsHovering2,
          isHovering3,
          setIsHovering3,
          isHovering4,
          setIsHovering4,
          isHovering5,
          setIsHovering5,
          isHovering6,
          setIsHovering6,
        }}
      >
        {props.children}
      </PackagesContext.Provider>
    </>
  );
};

export default PackagesState;
