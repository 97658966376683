import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { BUTTON, QR_LIST } from "../../constant";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";

const Qrscanmodal = (props) => {
  const { downloadQr } = props;
  const { modal, setModal, qr, qrId } = useContext(QrListAllContext);
  return (
    <Modal size="sm" isOpen={modal} toggle={() => setModal(!modal)}>
      <ModalHeader toggle={() => setModal(!modal)}>
        {QR_LIST.QR_SCAN}
      </ModalHeader>
      <ModalBody>
        <table>
          <tbody>
            <tr>
              <th>
                <img src={qr} alt="" style={{ width: "16rem" }} />
              </th>
            </tr>
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <th>
                <button
                  className="btn btn-primary"
                  onClick={() => downloadQr(qrId)}
                >
                  {BUTTON.DOWNLOADE}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};

export default Qrscanmodal;
