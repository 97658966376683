import * as React from "react";
import { useState } from "react";
import NfcListContext from "./NfcListContext";

const NfcListState = (props) => {
  const [nfcOrder, setNfcOrder] = useState([]);

  const [qr, setQr] = useState("");
  const [orderStatusId, setOrderStatusId] = useState("");
  const [nfcOrderId, setNfcOrderId] = useState("");
  const [nfcId, setNfcId] = useState("");
  const [nfcTitle, setNfcTitle] = useState("");

  const [modal, setModal] = useState(false);
  const [selectStatus, setSelectStatus] = useState(false);
  const [orderValue, setorderValue] = useState([]); //API -->

  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [progress, setProgress] = useState(0);

  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  return (
    <>
      <NfcListContext.Provider
        value={{
          nfcOrder,
          setNfcOrder,
          qr,
          setQr,
          orderStatusId,
          setOrderStatusId,
          nfcOrderId,
          setNfcOrderId,
          nfcId,
          setNfcId,
          nfcTitle,
          setNfcTitle,
          modal,
          setModal,
          orderValue,
          setorderValue,
          selectStatus,
          setSelectStatus,
          totalRecord,
          setTotalRecord,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          isHovering,
          setIsHovering,
          progress,
          setProgress,
          isHovering1,
          setIsHovering1,
          isHovering2,
          setIsHovering2,
          isHovering3,
          setIsHovering3,
        }}
      >
        {props.children}
      </NfcListContext.Provider>
    </>
  );
};

export default NfcListState;
