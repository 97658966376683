import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { Fragment, useContext } from "react";
import { Edit, Eye, Plus } from "react-feather";

import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { MuiTabelStyle, WHITE_LABEL_DOMAIN_CONSTANT } from "../../constant";
import DataNotFound from "../../DataNoteFound/DataNotFound";

import Breadcrumbs from "../../layout/breadcrumb";
import WhiteLabelDomainContext from "../../context/WhiteLabelDomainContext/WhiteLabelDomainContext";

const WhiteLabelDomainItem = (props) => {
  const { editHandle, showHandle, AddModel } = props;

  const { formvalue } = useContext(WhiteLabelDomainContext);

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "logo",
      headerName: "LOGO",
      renderCell: (params) => (
        <img src={params.row.data.logo} alt="logo" style={{ width: "130px" }} />
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "name",
      headerName: "NAME",
      renderCell: (params) => params.row.data.name,
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "url",
      headerName: "URL",
      renderCell: (params) => <a href={params.row.data.url}>Link</a>,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      renderCell: (params) => (
        <>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              // color: "#51bb25",
              color: "#7366ff",
            }}
            onClick={() => editHandle(params.row.id)}
          >
            <Edit style={{ width: "19px" }} />
          </button>
          {/* <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() => showHandle(params.row.id)}
          >
            <Eye style={{ width: "19px" }} />
          </button> */}
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent={WHITE_LABEL_DOMAIN_CONSTANT.PERENT} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3>{WHITE_LABEL_DOMAIN_CONSTANT.PERENT}</h3>
                <button
                  type="button"
                  style={{ border: "none", background: "none" }}
                  onClick={AddModel}
                >
                  <div
                    style={{
                      width: "50px",
                      background: "#7366ff",
                      borderRadius: "19rem",
                      color: "white",
                    }}
                  >
                    <h1>
                      <Plus />
                    </h1>
                  </div>
                </button>
              </CardHeader>
              <Box
                sx={{
                  "& .even": {
                    backgroundColor: "#f1f1f1",
                  },
                  "& .odd": {
                    backgroundColor: "white",
                  },
                }}
              >
                {formvalue.length && formvalue.length ? (
                  <>
                    <DataGrid
                      autoHeight
                      pagination
                      sx={MuiTabelStyle}
                      rows={formvalue}
                      getRowId={(row) => row.id}
                      columns={defaultColumns}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      disableSelectionOnClick
                      checkboxSelection={false}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  </>
                ) : (
                  <DataNotFound />
                )}
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default WhiteLabelDomainItem;
