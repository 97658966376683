import React, { useContext } from "react";
import { Card, CardHeader, Col, Form, Input, Row, Table } from "reactstrap";
import { Download, Eye } from "react-feather";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import moment from "moment";
import { MuiTabelStyle, QR_LIST } from "../../constant";

const QrListAllItem = (props) => {
  const {
    openModel,
    sorting,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    downloadQrIcon,
  } = props;

  const { qrAll, setFilter, filter } = useContext(QrListAllContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "title",
      headerName: "TITLE",
      renderCell: (params) => params.row.qrOrder.title,
    },
    {
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      field: "link",
      headerName: "LINK",
      renderCell: (params) => (
        <a
          href={
            params.row.qrOrder.orgDetail.qrDomain
              ? "https://" +
                params.row.qrOrder.orgDetail.qrDomain +
                "." +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
              : "https://" +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          View Link
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      field: "qrlogin",
      headerName: "QR ADMIN",
      renderCell: (params) => (
        <a
          href={
            params.row.qrOrder.orgDetail.qrDomain
              ? "https://" +
                params.row.qrOrder.orgDetail.qrDomain +
                "." +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId +
                "/admin"
              : "https://" +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId +
                "/admin"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Qr Login
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      field: "cardlogin",
      headerName: "CARD LOGIN",
      renderCell: (params) => (
        <a
          href={
            params.row.qrOrder.orgDetail.qrDomain
              ? `https://portal.${params.row.qrOrder.orgDetail.domain}`
              : `https://bytecard.in/`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Card Login
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      field: "hwId",
      headerName: "QR ID",
      renderCell: (params) => params.row.hwId,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "name",
      headerName: "CREATED BY",
      renderCell: (params) => params.row.qrOrder.usrDetail.name,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      renderCell: (params) => (
        <>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              openModel(
                params.row.url,
                params.row.qrId,
                params.row.qrOrder.title
              )
            }
          >
            <Eye style={{ width: "19px" }} />
          </button>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              downloadQrIcon(
                params.row.qrId,
                params.row.url,
                params.row.qrOrder.title
              )
            }
          >
            <Download style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h5>{QR_LIST.ORDER_LIST}</h5>
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Row>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Created By Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </th>
                </tr>
              </thead>
            </Table>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {qrAll.length && qrAll.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={qrAll}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.qrId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default QrListAllItem;
