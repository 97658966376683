import axios from "axios";
import {
  ADD_PACKAGES,
  EDIT_PACHAGES,
  ORG_LIST,
  PACKAGES_LIST,
  PACKAGE_DETAILS,
} from "../../constant/API constant";

export const PackageList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PACKAGES_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Package_Details = (packageId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${PACKAGE_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { packageId },
  });
};

export const Package_Edit = (formData, packageId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_PACHAGES}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { packageId },
    }
  );
};

export const Package_Add = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_PACKAGES}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const OrgList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};
