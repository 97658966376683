import React, { useContext } from "react";
import { Edit } from "react-feather";
import PhoneInput from "react-phone-number-input";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, ORGANIZATION } from "../../constant";
import OrgContext from "../../context/OrgContext/OrgContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import LoaderResponse from "../../Reuseable/Loader";

const OrganizationAddModel = (props) => {
  const { onSubmit, readUrl, closeModel, sslFileUploade } = props;

  const {
    modal,
    url,
    logo,
    setOrgName,
    orgName,
    setEmail,
    email,
    setGstIn,
    gstin,
    dialCode,
    setDialCode,
    phone,
    setPhone,
    setLandmark,
    landmark,
    setAdd1,
    add1,
    setAdd2,
    add2,
    setCity,
    city,
    state,
    setState,
    setPinCode,
    pinCode,
    setCountry,
    country,
    note,
    setNote,
    name,
    setName,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    isAddHandle,
    togglesubmit,
    setAdminEmail,
    adminEmail,
    setAdminNumber,
    adminNumber,
    check,
    setCheck,
    domain,
    setDomain,
    qrDomain,
    setQrDomain,
    baseUrl,
  } = useContext(OrgContext);

  const { loaderShow } = useContext(LoaderContext);

  /**
   * check to show feild function call
   */

  const onCheck = () => {
    if (check === true) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  };

  return (
    <>
      <Modal size="xl" isOpen={modal}>
        <ModalHeader toggle={() => closeModel()}>
          {togglesubmit ? (
            <span>{ORGANIZATION.ADD_ORG}</span>
          ) : (
            <span>
              {ORGANIZATION.EDIT_ORG} <span> </span>{" "}
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <LoaderResponse />
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5> {ORGANIZATION.ORG_DETAILS}</h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="4 mb-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={baseUrl + url}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                value={logo}
                                onChange={(e) => readUrl(e)}
                                // required
                                style={{
                                  border: "2px solid red",
                                  width: "10rem",
                                  position: "absolute",
                                  bottom: "-1rem",
                                  height: "12rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom01">
                          {ORGANIZATION.ORG_NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="text"
                          placeholder="Name"
                          id="validationCustom01"
                          onChange={(e) => setOrgName(e.target.value)}
                          value={orgName}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom02">
                          {ORGANIZATION.EMAIL}
                        </Label>
                        <InputGroup>
                          <InputGroupText>{"@"}</InputGroupText>
                          <Input
                            className="form-control"
                            name="email"
                            type="email"
                            id="validationCustom02"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid Email."}
                          </div>
                        </InputGroup>
                      </Col>
                      <Col md="4 mb-4">
                        <Label htmlFor="validationCustom03">
                          {ORGANIZATION.GST_IN}
                        </Label>
                        <Input
                          className="form-control"
                          name="GSTIn"
                          type="text"
                          id="validationCustom03"
                          placeholder="GST In"
                          onChange={(e) => setGstIn(e.target.value)}
                          value={gstin}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid GST In."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2 mb-4">
                        <Label htmlFor="validationCustom20">
                          {ORGANIZATION.DIAL_CODE}
                        </Label>
                        <InputGroup style={{ width: "112px" }}>
                          <PhoneInput
                            className="form-control"
                            international
                            placeholder="Enter phone number"
                            value={dialCode}
                            onChange={setDialCode}
                          />
                        </InputGroup>
                      </Col>
                      <Col md="4" mb="4">
                        <Label htmlFor="validationCustom03">
                          {ORGANIZATION.PHONE}
                        </Label>
                        <Input
                          className="form-control"
                          name="phone"
                          type="number"
                          id="validationCustome22"
                          placeholder="Phone"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                          required
                        />
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom05">
                          {ORGANIZATION.LANDMARK}
                        </Label>
                        <Input
                          className="form-control"
                          name="Indemark"
                          id="validationCustom05"
                          type="text"
                          placeholder="Landemark"
                          onChange={(e) => setLandmark(e.target.value)}
                          value={landmark}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Indemark."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom06">
                          {ORGANIZATION.ADDRESS1}
                        </Label>
                        <Input
                          className="form-control"
                          name="Address1"
                          id="validationCustom06"
                          type="text"
                          placeholder="Address 1"
                          onChange={(e) => setAdd1(e.target.value)}
                          value={add1}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Address 1."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom07">
                          {ORGANIZATION.ADDRESS2}
                        </Label>
                        <Input
                          className="form-control"
                          name="Address2"
                          id="validationCustom07"
                          type="text"
                          placeholder="Address 2"
                          onChange={(e) => setAdd2(e.target.value)}
                          value={add2}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Address 2."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom08">
                          {ORGANIZATION.CITY}
                        </Label>
                        <Input
                          className="form-control"
                          name="City"
                          id="validationCustom08"
                          type="text"
                          placeholder="City"
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid City."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom09">
                          {ORGANIZATION.STATE}
                        </Label>
                        <Input
                          className="form-control"
                          name="State"
                          id="validationCustom09"
                          type="text"
                          placeholder="State"
                          onChange={(e) => setState(e.target.value)}
                          value={state}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid State."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom10">
                          {ORGANIZATION.PINCODE}
                        </Label>
                        <Input
                          className="form-control"
                          name="PinCode"
                          id="validationCustom10"
                          type="number"
                          placeholder="Pin Code"
                          onChange={(e) => setPinCode(e.target.value)}
                          value={pinCode}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Pin Code."}
                        </div>
                      </Col>
                      <Col md="3 mb-4">
                        <Label htmlFor="validationCustom11">
                          {ORGANIZATION.COUNTRY}
                        </Label>
                        <Input
                          className="form-control"
                          name="Country"
                          id="validationCustom11"
                          type="text"
                          placeholder="Country"
                          onChange={(e) => setCountry(e.target.value)}
                          value={country}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Country."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <FormGroup>
                        <h6 className="form-label">{ORGANIZATION.NOTE}</h6>
                        <Input
                          type="textarea"
                          className="form-control"
                          rows="2"
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Note."}
                        </div>
                      </FormGroup>
                    </Row>
                    <br />
                    <div className="checkbox checkbox-primary">
                      <input
                        id="checkbox-primary-1"
                        type="checkbox"
                        onClick={onCheck}
                        checked={check === true}
                      />
                      <Label htmlFor="checkbox-primary-1">
                        {"Is White Label Solution "}
                      </Label>
                    </div>
                    <br />
                    {check && (
                      <Row>
                        <Col md="4 mb-4">
                          <Label htmlFor="validationCustom08">
                            {ORGANIZATION.DOMAIN}
                          </Label>
                          <Input
                            className="form-control"
                            name="City"
                            id="validationCustom08"
                            type="text"
                            placeholder="Domain"
                            onChange={(e) => setDomain(e.target.value)}
                            value={domain}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid City."}
                          </div>
                        </Col>
                        <Col md="4 mb-4">
                          <Label htmlFor="validationCustom09">
                            {ORGANIZATION.QR_DOMAIN}
                          </Label>
                          <Input
                            className="form-control"
                            name="State"
                            id="validationCustom09"
                            type="text"
                            placeholder="Qr Domain"
                            onChange={(e) => setQrDomain(e.target.value)}
                            value={qrDomain}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid State."}
                          </div>
                        </Col>
                        <Col md="4 mb-4">
                          <Label htmlFor="validationCustom11">
                            {ORGANIZATION.SSL_FILE}
                          </Label>
                          <Input
                            className="form-control"
                            type="file"
                            name="url"
                            id="formFile"
                            // value={elem.url}
                            onChange={(e) => sslFileUploade(e)}
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid Country."}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <hr />

                    <h5>{ORGANIZATION.ADMIN_DETAILS}</h5>
                    <hr />
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom14">
                          {ORGANIZATION.NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          id="validationCustom14"
                          type="text"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom16">
                          {ORGANIZATION.EMAIL}
                        </Label>
                        <InputGroup>
                          <InputGroupText>{"@"}</InputGroupText>
                          <Input
                            className="form-control"
                            id="validationCustom16"
                            name="adminemail"
                            type="email"
                            placeholder="Email"
                            onChange={(e) => setAdminEmail(e.target.value)}
                            value={adminEmail}
                            required
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid Admin Email."}
                          </div>
                        </InputGroup>
                      </Col>
                    </Row>
                    {isAddHandle ? (
                      <Row>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom15">
                            {ORGANIZATION.PASSWORD}
                          </Label>
                          <Input
                            className="form-control"
                            id="validationCustom15"
                            name="Password"
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid Password."}
                          </div>
                        </Col>
                        <Col md="6 mb-4">
                          <Label htmlFor="validationCustom17">
                            {ORGANIZATION.CONFIRM_PASSWORD}
                          </Label>
                          <Input
                            className="form-control"
                            id="validationCustom17"
                            name="ComformPassword"
                            type="password"
                            placeholder="ComformPassword"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            required
                          />
                          <div className="invalid-feedback">
                            {"Please provide a valid Comform Password."}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    <Row>
                      <Col md="2 mb-4">
                        <Label htmlFor="validationCustom18">
                          {ORGANIZATION.DIAL_CODE}
                        </Label>
                        <InputGroup style={{ width: "112px" }}>
                          <PhoneInput
                            className="form-control"
                            placeholder="Enter phone number"
                            international
                            value={dialCode}
                            onChange={setDialCode}
                          />
                        </InputGroup>
                      </Col>
                      <Col md="10" mb="4">
                        <Label htmlFor="validationCustom03">
                          {ORGANIZATION.PHONE}
                        </Label>
                        <Input
                          className="form-control"
                          name="phone"
                          type="number"
                          id="validationCustome21"
                          placeholder="Phone"
                          onChange={(e) => setAdminNumber(e.target.value)}
                          value={adminNumber}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid contact number."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrganizationAddModel;
