import React, { useEffect, Fragment, useContext, useState } from "react";
import { Container } from "reactstrap";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../layout/breadcrumb";
import UsersItem from "./usersItem";
import { UserList } from "../../api/Users Api/UserApi";
import UserContext from "../../context/userContext";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { USERS } from "../../constant";
import { tableId } from "../../utils/helper";
const Index = () => {
  const {
    setData,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    progress,
    setProgress,
    filter,
    data,
  } = useContext(UserContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * get user list function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: { name: filter },
      };
      const { data } = await UserList(paramsObj);
      if (data.status === 200) {
        setData(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setData([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);
  tableId(page, pageSize,data)
  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs parent={USERS.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />

          <UsersItem
            sorting={sorting}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            page={page}
            record={record}
          />
        </Container>
      </Fragment>
    </>
  );
};

export default Index;
