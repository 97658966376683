import axios from "axios";
import { ALL_QR, DOWNLOAD_QR } from "../../constant/API constant";

export const AllQrList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ALL_QR}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const DownloadQr = (qrId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DOWNLOAD_QR}`, {
    headers: { Authorization: jwt_token, "Content-Type": "image/png" },
    params: { qrId: qrId },
    responseType: "blob",
  });
};
