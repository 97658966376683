import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { Edit, Plus } from "react-feather";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { MuiTabelStyle, ORDER_STATUS_MASTER } from "../../constant";
import OrderMasterContext from "../../context/OrderMasterContext/OrderMasterContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";

const OrderStatusMasterItem = (props) => {
  const {
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    editHandle,
    sorting,
  } = props;

  const { orderValue } = useContext(OrderMasterContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      field: "statusName",
      headerName: "STATUS NAME",
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      renderCell: (params) => (
        <button
          className="_edit"
          type="button"
          style={{
            background: "none",
            textDecoration: "none",
            border: "none",
            color: "#7366ff",
          }}
          onClick={() => editHandle(params.row.orderStatusId)}
        >
          <Edit style={{ width: "19px" }} />
        </button>
      ),
    },
  ];

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h5>{ORDER_STATUS_MASTER.PERENT}</h5>
            <button
              type="button"
              style={{ border: "none", background: "none" }}
              onClick={AddModel}
            >
              <div
                style={{
                  width: "50px",
                  background: "#7366ff",
                  borderRadius: "19rem",
                  color: "white",
                }}
              >
                <h1>
                  <Plus />
                </h1>
              </div>
            </button>
          </CardHeader>
          <Box
            sx={{
              "& .even": {
                backgroundColor: "#f1f1f1",
              },
              "& .odd": {
                backgroundColor: "white",
              },
            }}
          >
            {orderValue.length && orderValue.length ? (
              <DataGrid
                autoHeight
                pagination
                sx={MuiTabelStyle}
                rows={orderValue}
                rowCount={record}
                pageSize={pageSize}
                getRowId={(row) => row.orderStatusId}
                columns={defaultColumns}
                page={page - 1}
                rowsPerPageOptions={[7, 10, 25, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                sortingMode="server"
                onSortModelChange={sorting}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            ) : (
              <DataNotFound />
            )}
          </Box>
        </Card>
      </Col>
    </Row>
  );
};

export default OrderStatusMasterItem;
