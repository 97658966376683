import * as React from "react";
import { useState } from "react";
import OrgContext from "./OrgContext";

const OrgState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [genFormvalue, setgenFormvalue] = useState([]); //API -->
  const [recordPerPageGenerated, setRecordPerPageGenerated] = useState(0);

  const [showWallet, setShowWallet] = useState(false);
  const [showAddWallet, setShowAddWallet] = useState(false);
  const [walletList, setWalletList] = useState([]);

  const [orgId, setOrgID] = useState("");
  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [gstin, setGstIn] = useState("");
  const [landmark, setLandmark] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [country, setCountry] = useState("");
  const [qrBel, setQrBel] = useState("");
  const [nfcBel, setNfcBel] = useState("");
  const [note, setNote] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const [logo] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [qty, setQty] = useState("");
  const [type, setType] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminNumber, setAdminNumber] = useState("");
  const [baseUrl, setBaseUrl] = useState("");

  const [check, setCheck] = useState(true);
  const [generated, setGenerated] = useState(false);
  const [domain, setDomain] = useState("");
  const [qrDomain, setQrDomain] = useState("");
  const [file, setFile] = useState("");

  const [balance, setBalance] = useState("");
  const [walletnote, setWalletNote] = useState("");
  const [walletImage, setWalletImage] = useState("");
  const [date, setDate] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [addbel, setAddBel] = useState(false); //ADD ORG BEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState("ASC"); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [url, setUrl] = useState("");
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  const [count, setCount] = useState(0);

  return (
    <>
      <OrgContext.Provider
        value={{
          showWallet,
          setShowWallet,
          formvalue,
          setformvalue,
          orgId,
          setOrgID,
          orgName,
          setOrgName,
          email,
          setEmail,
          gstin,
          setGstIn,
          landmark,
          setLandmark,
          add1,
          setAdd1,
          add2,
          setAdd2,
          city,
          setCity,
          state,
          setState,
          pinCode,
          setPinCode,
          country,
          setCountry,
          qrBel,
          setQrBel,
          nfcBel,
          setNfcBel,
          note,
          setNote,
          dialCode,
          setDialCode,
          phone,
          setPhone,
          logo,
          name,
          setName,
          password,
          setPassword,
          confirmPassword,
          setConfirmPassword,
          qty,
          setQty,
          type,
          setType,
          adminEmail,
          setAdminEmail,
          adminNumber,
          setAdminNumber,
          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          addbel,
          setAddBel,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          url,
          setUrl,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          check,
          setCheck,
          domain,
          setDomain,
          qrDomain,
          setQrDomain,
          file,
          setFile,
          generated,
          setGenerated,
          genFormvalue,
          setgenFormvalue,
          recordPerPageGenerated,
          setRecordPerPageGenerated,
          baseUrl,
          setBaseUrl,
          walletList,
          setWalletList,
          showAddWallet,
          setShowAddWallet,
          balance,
          setBalance,
          walletnote,
          setWalletNote,
          walletImage,
          setWalletImage,
          date,
          setDate,
          count,
          setCount,
        }}
      >
        {props.children}
      </OrgContext.Provider>
    </>
  );
};

export default OrgState;
