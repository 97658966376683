import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, NFC_ORDER_LIST } from "../../constant";
import NfcOrderListContext from "../../context/nfcOrderListContext/NfcOrderListContext";
import LoaderResponse from "../../Reuseable/Loader";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const TitleUpdate = (props) => {
  const { onSubmit, editTitleModel } = props;
  const { editTitle, title, setTitle } = useContext(NfcOrderListContext);
  const { loaderShow } = useContext(LoaderContext);
  return (
    <Modal size="lm" isOpen={editTitle} toggle={() => editTitleModel()}>
      <ModalHeader toggle={() => editTitleModel()}>
        {NFC_ORDER_LIST.EDIT_TITLE} <span> </span>{" "}
        <Edit style={{ width: "50px", height: "20px", color: "#7366ff" }} />
      </ModalHeader>
      <ModalBody>
        <LoaderResponse />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom5">
                        {NFC_ORDER_LIST.TITLE}
                      </Label>
                      <Input
                        className="form-control"
                        name="title"
                        id="validationCustom4"
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SAVE}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default TitleUpdate;
