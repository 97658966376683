import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, ORDER_STATUS_MASTER } from "../../constant";
import OrderMasterContext from "../../context/OrderMasterContext/OrderMasterContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import LoaderResponse from "../../Reuseable/Loader";

const OrderStatusAddModal = (props) => {
  const { onSubmit, closeModel } = props;

  const { modal, statusName, setStatusName, toggleSubmit } =
    useContext(OrderMasterContext);

  const { loaderShow } = useContext(LoaderContext);

  return (
    <Modal size="lg" isOpen={modal}>
      <ModalHeader toggle={() => closeModel()}>
        {toggleSubmit ? (
          <span>{ORDER_STATUS_MASTER.ADD_ORDER_STATUS}</span>
        ) : (
          <span>
            {ORDER_STATUS_MASTER.EDIT_ORDER_STATUS} <span> </span>{" "}
            <Edit style={{ width: "50px", height: "20px", color: "#7366ff" }} />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <LoaderResponse />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom1">
                        {ORDER_STATUS_MASTER.NAME}
                      </Label>
                      <Input
                        className="form-control"
                        name="name"
                        placeholder="Status Name"
                        value={statusName}
                        onChange={(e) => setStatusName(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SAVE}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default OrderStatusAddModal;
