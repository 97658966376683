import React, { useContext, useEffect, useState } from "react";

import OrganizationItem from "./organizationItem";
import OrganizationDetailsModel from "./organizationDetailsModel";
import OrganizationAddModel from "./organizationAddModel";

import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

import { useForm } from "react-hook-form";
import AddOrgBelModel from "./addOrgBelModel";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import {
  Add_Org,
  Add_Org_Balance,
  Edit_Org,
  FILE_API,
  OrgList,
  Org_Details,
  Org_Enable_Desable,
  Org_Wallet,
} from "../../api/Organization Api/OrganizationApi";
import OrgContext from "../../context/OrgContext/OrgContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import ShowWallet from "./ShowWallet";
import AddWallet from "./addWallet";
import { tableId } from "../../utils/helper";

const Organization = () => {
  const {
    setformvalue,
    orgId,
    setOrgID,
    orgName,
    setOrgName,
    email,
    setEmail,
    gstin,
    setGstIn,
    landmark,
    setLandmark,
    add1,
    setAdd1,
    add2,
    setAdd2,
    city,
    setCity,
    state,
    setState,
    pinCode,
    setPinCode,
    country,
    setCountry,
    setQrBel,
    setNfcBel,
    note,
    setNote,
    dialCode,
    setDialCode,
    phone,
    setPhone,
    name,
    setName,
    password,
    confirmPassword,
    qty,
    setQty,
    type,
    setType,
    adminEmail,
    setAdminEmail,
    adminNumber,
    setAdminNumber,
    setahoeDataModel,
    setModal,
    modal,
    setAddBel,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    filter,
    setIsHovering,
    isSwitchOn,
    setIsSwitchOn,
    url,
    setUrl,
    editIndex,
    seteditIndex,
    setIsAddHandle,
    progress,
    setProgress,
    togglesubmit,
    setToggle,
    check,
    domain,
    setDomain,
    qrDomain,
    setQrDomain,
    file,
    setFile,
    setBaseUrl,
    baseUrl,
    setShowWallet,
    setWalletList,
    setCount,
    formvalue,
  } = useContext(OrgContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * Organization list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        paramsObj["search"] = { orgName: filter };
      }
      const { data } = await OrgList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  tableId(page, pageSize, formvalue);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  /**
   * add image function call
   */

  const readUrl = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result);
    };

    let formData = new FormData();
    formData.append("type", 1);
    formData.append("file", event.target.files[0]);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setUrl(data.payload.imageUrl);
        setBaseUrl(data.payload.baseUrl);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * add zip function call
   */
  const sslFileUploade = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/zip\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setFile(reader.result);
    };

    let formData = new FormData();
    formData.append("type", 4);
    formData.append("file", event.target.files[0]);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setFile(data.payload.baseUrl + data.payload.imageUrl);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * edit organization function call
   */

  const editHandle = async (orgId) => {
    // debugger;
    setOrgID(orgId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Org_Details(orgId);
      if (data.status === 200) {
        setToggle(false);
        setIsAddHandle(false);
        seteditIndex(orgId);
        setModal(true);
        //ORG EDIT
        setUrl(data.payload.data.logo);
        setOrgName(data.payload.data.orgName);
        setEmail(data.payload.data.email);
        setGstIn(data.payload.data.gstin);
        setLandmark(data.payload.data.landmark);
        setAdd1(data.payload.data.add1);
        setAdd2(data.payload.data.add2);
        setCity(data.payload.data.city);
        setState(data.payload.data.state);
        setPinCode(data.payload.data.pinCode);
        setCountry(data.payload.data.country);
        setQrBel(data.payload.data.qrBel);
        setNfcBel(data.payload.data.nfcBal);
        setNote(data.payload.data.note);
        setDialCode(data.payload.data.dialCode);
        setPhone(data.payload.data.phone);
        // ADMIN EDIT
        setName(data.payload.organizationAdminDetail.name);
        setAdminEmail(data.payload.organizationAdminDetail.email);
        setAdminNumber(data.payload.organizationAdminDetail.phone);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const { register } = useForm();

  /**
   * Empty feild function call
   */

  const emptyFeild = () => {
    setName("");
    setEmail("");
    setPhone("");
    setDialCode("");
    setOrgName("");
    setPhone("");
    setGstIn("");
    setLandmark("");
    setAdd1("");
    setAdd2("");
    setCity("");
    setState("");
    setPinCode("");
    setCountry("");
    setNote("");
    setAdminEmail("");
    setAdminNumber("");
    setFile("");
    setQrDomain("");
    setDomain("");
    setBaseUrl("");
    setUrl("");
    setLoaderShow(false);
  };

  /**
   * fill Feild function call
   */

  const fillFeild = () => {
    setName(name);
    setEmail(email);
    setPhone(phone);
    setDialCode(dialCode);
    setOrgName(orgName);
    setPhone(phone);
    setGstIn(gstin);
    setLandmark(landmark);
    setAdd1(add1);
    setAdd2(add2);
    setCity(city);
    setState(state);
    setPinCode(pinCode);
    setCountry(country);
    setNote(note);
    setAdminEmail(adminEmail);
    setAdminNumber(adminNumber);
    setFile(file);
    setQrDomain(qrDomain);
    setDomain(domain);
    setUrl(url);
    setBaseUrl(baseUrl);
    setLoaderShow(false);
  };

  /**
   * create and edit function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;

    if (editIndex > -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);
      let logo = url.split("byte-nfc/").pop();
      formData = {
        user: {
          name: name,
          email: adminEmail,
          phone: adminNumber,
          dialCode: dialCode,
        },
        logo: logo,
        orgId: orgId,
        email: email,
        orgName: orgName,
        dialCode: dialCode,
        gstin: gstin,
        landmark: landmark,
        add1: add1,
        add2: add2,
        phone: phone,
        city: city,
        state: state,
        pinCode: pinCode,
        country: country,
        note: note,
        domain: domain,
        qrDomain: qrDomain,
        domainSSL: file,
        isSubDomain: check,
      };

      try {
        const { data } = await Edit_Org(formData, orgId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        user: {
          name: name,
          email: adminEmail,
          phone: adminNumber,
          dialCode: dialCode,
          password: password,
          confirmPassword: confirmPassword,
        },
        logo: url,
        email: email,
        orgName: orgName,
        phone: phone,
        dialCode: dialCode,
        gstin: gstin,
        landmark: landmark,
        add1: add1,
        add2: add2,
        city: city,
        state: state,
        pinCode: pinCode,
        country: country,
        note: note,
        domain: domain,
        qrDomain: qrDomain,
        domainSSL: file,
        isSubDomain: check,
      };

      try {
        const { data } = await Add_Org(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * Show popup function call
   */

  const showHandle = async (orgId) => {
    setOrgID(orgId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Org_Details(orgId);
      if (data.status === 200) {
        setahoeDataModel(true);

        setUrl(data.payload.data.logo);
        setOrgName(data.payload.data.orgName);
        setEmail(data.payload.data.email);
        setGstIn(data.payload.data.gstin);
        setLandmark(data.payload.data.landmark);
        setAdd1(data.payload.data.add1);
        setAdd2(data.payload.data.add2);
        setCity(data.payload.data.city);
        setState(data.payload.data.state);
        setPinCode(data.payload.data.pinCode);
        setCountry(data.payload.data.country);
        setQrBel(data.payload.data.qrBal);
        setNfcBel(data.payload.data.nfcBal);
        setNote(data.payload.data.note);
        setDialCode(data.payload.data.dialCode);
        setPhone(data.payload.data.phone);

        setName(data.payload.organizationAdminDetail.name);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * add balance function call and popup open
   */

  const AddBalance = (orgId) => {
    setOrgID(orgId);
    seteditIndex(orgId);
    setAddBel(true);
  };

  /**
   * balance emply feild function call
   */

  const emptyBalance = () => {
    setQty("");
    setType("");
    setOrgID("");
    setLoaderShow(false);
  };

  /**
   * balance fill feild function call
   */

  const fillBalance = () => {
    setQty(qty);
    setType(setQty);
    setOrgID(orgId);
    setLoaderShow(false);
  };

  /**
   * addbalance submit form function call
   */

  const addBelence = async (e) => {
    e.preventDefault();
    let formData;
    setOrgID(orgId);
    setLoaderShow(!loaderShow);
    if (editIndex > -1) {
      formData = {
        qty: qty,
        type: type,
        orgId: orgId,
      };
      try {
        const { data } = await Add_Org_Balance(formData);
        if (data.status === 200) {
          toast.success("Add Balance Sucessfully", {
            autoClose: 1000,
            transition: Zoom,
          });
          setAddBel(false);
          getData();
          emptyBalance();
        } else {
          fillBalance();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const showWalletModal = async (orgId, balance) => {
    let paramsObj = {
      showAll: true,
      orgId,
    };
    setOrgID(orgId);
    setCount(balance);
    try {
      const { data } = await Org_Wallet(paramsObj);
      if (data.status === 200) {
        setShowWallet(true);
        setWalletList(data.payload.data);
      } else {
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * srting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  /**
   * enable / desable function call
   */

  const switch_onChange_handle = async (orgId, status) => {
    let formData;
    formData = {
      orgId: orgId,
      isActive: status ? false : true,
    };
    try {
      const { data } = await Org_Enable_Desable(formData, orgId);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        setIsSwitchOn(!isSwitchOn);
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <OrganizationAddModel
          onSubmit={onSubmit}
          readUrl={readUrl}
          register={register}
          editHandle={editHandle}
          closeModel={closeModel}
          sslFileUploade={sslFileUploade}
        />
        <AddOrgBelModel addBelence={addBelence} />
        <OrganizationDetailsModel />
        <OrganizationItem
          handleMouseOver={handleMouseOver}
          handleMouseOut={handleMouseOut}
          sorting={sorting}
          editHandle={editHandle}
          showHandle={showHandle}
          switch_onChange_handle={switch_onChange_handle}
          AddBalance={AddBalance}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          showWalletModal={showWalletModal}
        />
        <ShowWallet />
        <AddWallet showWalletModal={showWalletModal} getData={getData} />
      </div>
    </>
  );
};
export default Organization;
