import * as React from "react";
import { useState } from "react";
import WhiteLabelDomainContext from "./WhiteLabelDomainContext";

const WhiteLabelDomainState = (props) => {
  const [formvalue, setformvalue] = useState([]); //API -->
  const [genFormvalue, setgenFormvalue] = useState([]); //API -->
  const [recordPerPageGenerated, setRecordPerPageGenerated] = useState(0);

  const [orgId, setOrgID] = useState("");

  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [whitelabelDomain, setWhiteLabelDomain] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  const [ahoeDataModel, setahoeDataModel] = useState(false); //SHOW ORG DETAILS MODEL -->
  const [modal, setModal] = useState(false); //ADD DATA MODEL -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState("ASC"); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [filter, setFilter] = useState(""); //SEARCHING FILTER -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [isSwitchOn, setIsSwitchOn] = useState(false); //TOGGLE SWITCH -->
  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isAddHandle, setIsAddHandle] = useState(true); //PASS HIDE OR UNHIDE -->
  const [progress, setProgress] = useState(0);
  const [togglesubmit, setToggle] = useState(false);

  return (
    <>
      <WhiteLabelDomainContext.Provider
        value={{
          formvalue,
          setformvalue,
          orgId,
          setOrgID,
          ahoeDataModel,
          setahoeDataModel,
          modal,
          setModal,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          filter,
          setFilter,
          isHovering,
          setIsHovering,
          isSwitchOn,
          setIsSwitchOn,
          editIndex,
          seteditIndex,
          totalRecord,
          setTotalRecord,
          isAddHandle,
          setIsAddHandle,
          progress,
          setProgress,
          togglesubmit,
          setToggle,
          logo,
          setLogo,
          favicon,
          setFavicon,
          whitelabelDomain,
          setWhiteLabelDomain,
          name,
          setName,
          url,
          setUrl,
          genFormvalue,
          setgenFormvalue,
          recordPerPageGenerated,
          setRecordPerPageGenerated,
        }}
      >
        {props.children}
      </WhiteLabelDomainContext.Provider>
    </>
  );
};

export default WhiteLabelDomainState;
