import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, NFC_ORDER_LIST } from "../../constant";
import NfcOrderListContext from "../../context/nfcOrderListContext/NfcOrderListContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import LoaderResponse from "../../Reuseable/Loader";

const CreateNfc = (props) => {
  const { createNfc, nfcModelClose } = props;
  const {
    setQty,
    qty,
    setTitle,
    title,
    setFrontImg,
    check,
    setCheck,
    nfcmodal,
  } = useContext(NfcOrderListContext);
  const { loaderShow } = useContext(LoaderContext);
  return (
    <Modal size="lg" isOpen={nfcmodal} toggle={() => nfcModelClose()}>
      <ModalHeader toggle={() => nfcModelClose()}>
        {NFC_ORDER_LIST.CREATE_NFC}
      </ModalHeader>
      <ModalBody>
        <LoaderResponse />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => createNfc(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom4">
                        {NFC_ORDER_LIST.QTY}
                      </Label>
                      <Input
                        className="form-control"
                        name="qty"
                        id="validationCustom4"
                        type="number"
                        placeholder="QTY"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom5">
                        {NFC_ORDER_LIST.TITLE}
                      </Label>
                      <Input
                        className="form-control"
                        name="title"
                        id="validationCustom4"
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ marginBottom: "25px" }}>
                      <input
                        type="file"
                        name="img"
                        className="form-control"
                        id="customFile"
                        onChange={(e) => setFrontImg(e.target.files[0])}
                        accept="image/svg"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value={check}
                        onChange={(e) => setCheck(e.target.checked)}
                      />
                      <label htmlFor="vehicle1">
                        {" "}
                        {NFC_ORDER_LIST.IS_SAME}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "18px",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SAVE}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CreateNfc;
