import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext } from "react";
import { Edit, Plus } from "react-feather";
import { Card, CardHeader, Col, Row } from "reactstrap";
import {
  MuiTabelStyle,
  NFC_GUNRATE_STATUS,
  NFC_ORDER_LIST,
  USER_ROLE,
} from "../../constant";
import NfcOrderListContext from "../../context/nfcOrderListContext/NfcOrderListContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";

const NfcOrderListItem = (props) => {
  const {
    sorting,
    handleRegenerate,
    handleEdit,
    pageSize,
    page,
    record,
    setPageSize,
    setPage,
  } = props;

  const { nfcorderValue, setNfcModal } = useContext(NfcOrderListContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 230,
      field: "orgName",
      headerName: "ORGANIZATION NAME",
      renderCell: (params) => params.row.orgDetail.orgName,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "frontImg",
      headerName: "IMAGE",
      sortable: false,
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={params.row.frontImg}
          alt="photo"
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 240,
      field: "title",
      headerName: "TITLE",
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "qty",
      headerName: "QTY",
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "isSame",
      headerName: "SAME",
      renderCell: (params) => (params.row.isSame === false ? "No" : "Yes"),
    },
    USER_ROLE.ADMIN === +localStorage.getItem("roleId")
      ? {
          flex: 0.1,
          minWidth: 170,
          sortable: false,
          field: "status",
          headerName: "STATUS",
          renderCell: (params) =>
            params.row.status === NFC_GUNRATE_STATUS.CREATED &&
            params.row.status === NFC_GUNRATE_STATUS.FAILED ? (
              <i class="fa fa-circle font-danger f-12"></i>
            ) : (
              <i class="fa fa-circle font-success f-12"></i>
            ),
        }
      : {
          flex: 0.1,
          minWidth: 170,
          sortable: false,
          field: "status",
          headerName: "STATUS",
          renderCell: (params) =>
            params.row.status === NFC_GUNRATE_STATUS.CREATED &&
            params.row.status === NFC_GUNRATE_STATUS.FAILED ? (
              <button
                className="btn btn-danger"
                onClick={() => handleRegenerate(params.row.nfcOrderId)}
              >
                ReGenerate
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => handleRegenerate(params.row.nfcOrderId)}
                disabled
              >
                Generate
              </button>
            ),
        },
    {
      flex: 0.1,
      minWidth: 200,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:MM"),
    },
    {
      field: "actions",
      sortable: false,
      headerName: "ACTIONS",
      renderCell: (params) => (
        <button
          style={{
            background: "none",
            textDecoration: "none",
            border: "none",
            color: "rgb(115, 102, 255)",
          }}
          onClick={() => handleEdit(params.row.nfcOrderId)}
        >
          <Edit style={{ width: "19px" }} />
        </button>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{NFC_ORDER_LIST.ORDER_LIST}</h3>
              {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
                ""
              ) : (
                <button
                  type="button"
                  style={{ border: "none", background: "none" }}
                  onClick={() => setNfcModal(true)}
                >
                  <div
                    style={{
                      width: "50px",
                      background: "#7366ff",
                      borderRadius: "19rem",
                      color: "white",
                    }}
                  >
                    <h1>
                      <Plus />
                    </h1>
                  </div>
                </button>
              )}
            </CardHeader>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {nfcorderValue.length && nfcorderValue.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={nfcorderValue}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.nfcOrderId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NfcOrderListItem;
