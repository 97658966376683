import React, { useContext, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import OrgContext from "../../context/OrgContext/OrgContext";
import { BUTTON, FILE_TYPE } from "../../constant";
import { Zoom, toast } from "react-toastify";
import { Edit_Org, FILE_API } from "../../api/Organization Api/OrganizationApi";
import moment from "moment";

const AddWallet = (props) => {
  const { showWalletModal, getData } = props;
  const {
    showAddWallet,
    setShowAddWallet,
    balance,
    setBalance,
    walletnote,
    setWalletNote,
    walletImage,
    setWalletImage,
    date,
    setDate,
    orgId,
  } = useContext(OrgContext);
  const [buttonDesabled, setButtonDesabled] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setButtonDesabled(true);
    let formData = {
      date: moment(date).format("MM-DD-YYYY"),
      notes: walletnote,
      balance: balance,
      orgId,
    };
    if (walletImage) {
      const { imageUrl, baseUrl } = await uploadImage(
        FILE_TYPE.WALLET,
        walletImage
      );
      formData["image"] = imageUrl;
    }
    try {
      const { data } = await Edit_Org(formData, orgId);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
        });
        setShowAddWallet(false);
        showWalletModal(orgId);
        getData();
        setBalance("");
        setWalletImage("");
        setWalletNote("");
        setDate("");
        setButtonDesabled(false);
      } else {
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setButtonDesabled(false);
    }
  };

  const uploadImage = async (type, url) => {
    let formData = new FormData();
    formData.append("type", type);
    formData.append("file", url);

    try {
      const { data } = await FILE_API(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        return {
          imageUrl: data.payload.imageUrl,
          baseUrl: data.payload.baseUrl,
        };
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  return (
    <>
      <Modal size="xl" isOpen={showAddWallet}>
        <ModalHeader toggle={() => setShowAddWallet(!showAddWallet)}>
          Add Wallet
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"image"}</Label>
                        <Input
                          className="form-control"
                          name="imageFile"
                          type="file"
                          placeholder="Amount"
                          id="validationCustom01"
                          onChange={(e) => setWalletImage(e.target.files[0])}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"date"}</Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="date"
                          placeholder="Date"
                          id="validationCustom01"
                          onChange={(e) => setDate(e.target.value)}
                          value={date}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Balance"}</Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="number"
                          placeholder="Amount"
                          id="validationCustom01"
                          onChange={(e) => setBalance(e.target.value)}
                          value={balance}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">{"Note"}</Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="text"
                          placeholder="Note"
                          id="validationCustom01"
                          onChange={(e) => setWalletNote(e.target.value)}
                          value={walletnote}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={buttonDesabled === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddWallet;
