import React, { useContext } from "react";
import OrgContext from "../../context/OrgContext/OrgContext";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import moment from "moment";

const ShowWallet = () => {
  const {
    showWallet,
    setShowWallet,
    walletList,
    setShowAddWallet,
    showAddWallet,
    count,
  } = useContext(OrgContext);
  const addWallet = () => {
    setShowAddWallet(!showAddWallet);
  };
  let total = 0;
  for (const i of walletList) {
    total += i.amount;
  }
  return (
    <>
      <Modal
        size="xl"
        isOpen={showWallet}
        toggle={() => setShowWallet(!showWallet)}
      >
        <ModalHeader toggle={() => setShowWallet(!showWallet)}>
          Wallet : {count ? count : total}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <Button onClick={() => addWallet()}>Add Wallet</Button>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "10px" }}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col">{"Type"}</th>
                            <th scope="col">{"Amount"}</th>
                            <th scope="col">{"Note"}</th>
                            <th scope="col">{"Date"}</th>
                          </tr>
                        </thead>
                        {walletList?.map((elem) => (
                          <tbody key={elem.walletId}>
                            <tr>
                              <td
                                style={{
                                  color: elem.type === 1 ? "green" : "red",
                                }}
                              >
                                {elem.type === 1 ? "Credit" : "Debit"}
                              </td>
                              <td>{elem.amount}</td>
                              <td>{elem.notes}</td>
                              <td>
                                {moment(elem.date).format("DD MMM, YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ShowWallet;
