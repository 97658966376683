import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Download } from "react-feather";
import { toast, Zoom } from "react-toastify";
import { Card, CardHeader, Col, Input, Row, Table } from "reactstrap";
import { DownloadNfcQr, NFCList } from "../../api/Nfc List Api/NfcListApi";
import OrgContext from "../../context/OrgContext/OrgContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";

const GeneratedNfc = () => {
  const [formvalue, setformvalue] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [filter, setFilter] = useState("");

  const { orgId } = useContext(OrgContext);

  /**
   * get nfc list api call function
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        orgId: orgId || localStorage.getItem("orgIdGenNfc"),
      };
      if (filter.length) {
        paramsObj["search"] = { title: filter };
      }
      const { data } = await NFCList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [orgId, page, pageSize, filter]);

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 120,
      field: "nfcId",
      headerName: "ID",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 220,
      field: "orgName",
      headerName: "ORGANIZATION NAME",
      disableColumnMenu: true,
      renderCell: (params) => params.row.nfcOrder.orgDetail.orgName,
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "title",
      headerName: "TITLE",
      disableColumnMenu: true,
      renderCell: (params) => params.row.nfcOrder.title,
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "frontImg",
      sortable: false,
      headerName: "IMAGE",
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={params.row.nfcOrder.frontImg}
          alt="photo"
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "hwId",
      sortable: false,
      headerName: "LINK",
      disableColumnMenu: true,
      renderCell: (params) => (
        <a
          href={"https://bytenfc.com/" + params.row.hwId}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Link
        </a>
      ),
    },

    {
      flex: 0.1,
      minWidth: 120,
      field: "isSame",
      headerName: "SAME",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.row.nfcOrder.isSame === false ? "No" : "Yes",
    },

    {
      flex: 0.1,
      minWidth: 200,
      field: "statusName",
      headerName: "STATUS",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => params.row.nfcOrder.orderStatus.statusName,
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "name",
      headerName: "CREATED",
      disableColumnMenu: true,
      renderCell: (params) => params.row.nfcOrder.usrDetail.name,
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "createdAt",
      headerName: "CREATED AT At",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:MM"),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <>
          {/* <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            // onClick={() =>
            //   openModel(
            //     params.row.pngLink,
            //     params.row.nfcId,
            //     params.row.nfcOrder.title
            //   )
            // }
          >
            <Eye style={{ width: "19px" }} />
          </button> */}
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() =>
              downloadQrIcon(params.row.nfcId, params.row.nfcOrder.title)
            }
          >
            <Download style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  const downloadQrIcon = async (nfcId, title) => {
    await DownloadNfcQr(nfcId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h3>{"Nfc List"}</h3>
          </CardHeader>
          <Table>
            <thead>
              <tr>
                <th>
                  <Row>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Input
                          className="form-control-plaintext"
                          type="text"
                          placeholder="Generated Qr Search ..."
                          value={filter}
                          onInput={(e) => setFilter(e.target.value)}
                          style={{
                            border: "1px solid #ecf3fa",
                            borderRadius: "8px",
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
          </Table>
          <Box
            sx={{
              "& .even": {
                backgroundColor: "#f1f1f1",
              },
              "& .odd": {
                backgroundColor: "white",
              },
            }}
          >
            {formvalue.length ? (
              <DataGrid
                autoHeight
                pagination
                rows={formvalue}
                rowCount={record}
                pageSize={pageSize}
                getRowId={(row) => row.nfcId}
                columns={defaultColumns}
                page={page - 1}
                rowsPerPageOptions={[7, 10, 25, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            ) : (
              <DataNotFound />
            )}
          </Box>
        </Card>
      </Col>
    </Row>
  );
};

export default GeneratedNfc;
