import {
  Home,
  Table,
  Users,
  Package,
  ShoppingBag,
  Activity,
  Info,
  User,
  Key,
  Tag,
  Archive,
} from "react-feather";

let admin = `Hello, Admin`;

export const MENUITEMS = [
  {
    menutitle: admin,

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/users/users`,
        icon: Users,
        title: "Users",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/organization/organization`,
        icon: Table,
        title: "Organization",
        type: "link",
      },

      {
        path: `${process.env.PUBLIC_URL}/packages/package`,
        icon: Package,
        title: "Packages",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/shipingmaster/shipingmaster`,
        icon: ShoppingBag,
        title: "Shipping Master",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/orderstatusmaster/orderstatusmaster`,
        icon: Activity,
        title: "Order Status Master",
        type: "link",
      },
      {
        title: "QR",
        icon: Info,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/orderList/orderList`,
            title: "Order List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/qrListAll/qrListAll`,
            title: "QR List All",
            type: "link",
          },
        ],
      },
      {
        title: "NFC",
        icon: Info,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/nfcOrderList/orderList`,
            title: "Order List",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/nfcList/nfcList`,
            title: "NFC List",
            type: "link",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/whiteLabelDomain/whiteLabelDomain`,
        icon: Tag,
        title: "White Label Domain",
        type: "link",
      },
      {
        title: "Roles & Privillages",
        icon: Key,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager`,
            title: "Privillage Manager",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager`,
            title: "Module Manager",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const SELLER_MENUITEMS = [
  {
    menutitle: "Hello, " + localStorage.getItem("name"),

    Items: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
        icon: Home,
        title: "Dashboard",
        type: "link",
      },
      {
        title: "Qr / Card Order",
        icon: Info,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/orderList/orderList`,
            title: "Create Qr / Card",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/qrListAll/qrListAll`,
            title: "List Qr / Card",
            type: "link",
          },
        ],
      },
      {
        path: `${process.env.PUBLIC_URL}/wallet/Walltet`,
        icon: Archive,
        title: "Shipping Wallet",
        type: "link",
      },
      // {
      //   title: "NFC",
      //   icon: Info,
      //   type: "sub",
      //   active: false,
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/nfcOrderList/orderList`,
      //       title: "Order List",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/nfcList/nfcList`,
      //       title: "NFC List",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        path: `${process.env.PUBLIC_URL}/userProfile/userProfile`,
        icon: User,
        title: "Profile",
        type: "link",
      },
    ],
  },
];
