export const SERVICES = {
  BYTEQR: "/byte-qr",
  _PUBLIC: "/byte-qr/public",
  GETWAY: "/gateway/admin",
  WHITE_LABEL_DOMAIN: "/api/whiteLabeledData",
};

export const V1 = "/api/v1";
export const API = "/api";

/** LOGIN */

export const LOGIN_ = `${SERVICES._PUBLIC}${V1}/login`;

/** LOGIN */

/**CHNAGE PASS AND EMAIL */
export const CHNAGE_PASS = `${API}/changePasswordFromEmail`;
export const CHNAGE_EMAIL = `${API}/changeOrganizationEmailId`;
/**CHNAGE PASS AND EMAIL */

/** USER_LIST */

export const USER_LIST = `${SERVICES.BYTEQR}${V1}/user`;

/** USER_LIST */

/** ORGANIZATION*/
export const ORG_LIST = `${SERVICES.BYTEQR}${V1}/organization`;
export const ADD_ORG = `${SERVICES.BYTEQR}${V1}/organization`;
export const ADD_ORG_BALANCE = `${SERVICES.BYTEQR}${V1}/organization/addOrganizationBalance`;
export const ORG_DITAILS = `${SERVICES.BYTEQR}${V1}/organization/detail`;
export const ORG_PROFILE = `${SERVICES.BYTEQR}${V1}/organization/profile`;
export const EDIT_ORG = `${SERVICES.BYTEQR}${V1}/organization`;
export const ORG_ENABLE_DESABLE = `${SERVICES.BYTEQR}${V1}/organization/enableDisable`;
export const ORG_WALLET = `${SERVICES.BYTEQR}${V1}/wallet`;
export const FILE = `${SERVICES._PUBLIC}${V1}/file`;
/** ORGANIZATION*/

/** PACKAGES*/
export const PACKAGES_LIST = `${SERVICES.BYTEQR}${V1}/package_master`;
export const ADD_PACKAGES = `${SERVICES.BYTEQR}${V1}/package_master`;
export const EDIT_PACHAGES = `${SERVICES.BYTEQR}${V1}/package_master`;
export const PACKAGE_DETAILS = `${SERVICES.BYTEQR}${V1}/package_master/detail`;
/** PACKAGES*/

/** SHIPPING MASTER*/
export const SHIPPING_LIST = `${SERVICES.BYTEQR}${V1}/shipping_company_master`;
export const SHIPPING_ADD = `${SERVICES.BYTEQR}${V1}/shipping_company_master`;
export const SHIPPING_DETAILS = `${SERVICES.BYTEQR}${V1}/shipping_company_master/detail`;
export const EDIT_SHIPPING = `${SERVICES.BYTEQR}${V1}/shipping_company_master`;
/** SHIPPING MASTER*/

/** ORDER STATUS LIST*/
export const ORDER_STATUS_LIST = `${SERVICES.BYTEQR}${V1}/order_status_master`;
export const ADD_ORDER_STATUS = `${SERVICES.BYTEQR}${V1}/order_status_master`;
export const ORDER_STATUS_DETAILS = `${SERVICES.BYTEQR}${V1}/order_status_master/detail`;
export const EDIT_ORDER_STATUS = `${SERVICES.BYTEQR}${V1}/order_status_master`;
/** ORDER STATUS LIST*/

/** QR ORDER*/
export const QR_LIST = `${SERVICES.BYTEQR}${V1}/qr_order`;
export const QR_REGUNRATE = `${SERVICES.BYTEQR}${V1}/qr_order/re-generate-order`;
export const CREATE_QR = `${SERVICES.BYTEQR}${V1}/qr_order`;
export const ALL_QR = `${SERVICES.BYTEQR}${V1}/qr_order/generated-qr-list`;
export const OR_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/qr_order`;
export const QR_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/qr_order/detail`;
/** QR ORDER*/

/** NFC ORDER*/
export const NFC_ORDER = `${SERVICES.BYTEQR}${V1}/nfc_order`;
export const NFC_LIST = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-list`;
export const CREATE_NFC = `${SERVICES.BYTEQR}${V1}/nfc_order`;
export const NFC_REGENARATE = `${SERVICES.BYTEQR}${V1}/nfc_order/re-generate-order`;
/** NFC ORDER*/

/** DASHBOARD*/
export const SUPER_ADMIN_DESHBOARD = `${SERVICES.BYTEQR}${V1}/super_admin_dashboard/detail`;
export const SELLER_DESHBOARD = `${SERVICES.BYTEQR}${V1}/seller_dashboard/detail`;
export const NFC__LIST_STATUS_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order/status`;
export const NFC_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/generated-nfc-detail`;
export const NFC_ORDER_LIST_DETAILS = `${SERVICES.BYTEQR}${V1}/nfc_order/detail`;
export const NFC_ORDER_LIST_TITLE_UPDATE = `${SERVICES.BYTEQR}${V1}/nfc_order`;
/** DASHBOARD*/

/** SELLER PASSWORD CHANGE */
export const PASSWORD_CHANGE = `${SERVICES.BYTEQR}${V1}/user/change-password`;
/** SELLER PASSWORD CHANGE */

/** DOWNLOAD QR  */
export const DOWNLOAD_QR = `${SERVICES.BYTEQR}${V1}/qr_order/qrGeneratedDetails`;
export const DOWNLOAD_NFC = `${SERVICES.BYTEQR}${V1}/nfc_order/nfcGeneratedDetails`;
/** DOWNLOAD QR  */

/** PRIVILLAGE MASTER LIST*/
export const PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const ADD_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const EDIT_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DELETE_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege`;
export const DETAILS_PRIVILLAGE_LIST = `${SERVICES.GETWAY}${V1}/privilege/detail`;
export const MODULE_ACCESS_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/access`;
/** PRIVILLAGE MASTER LIST*/

/** MODUAL MASTER LIST*/
export const MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const ADD_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DETAILS_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module/detail`;
export const EDIT_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
export const DELETE_MODULE_LIST = `${SERVICES.GETWAY}${V1}/privilege/module`;
/** MODUAL MASTER LIST*/

/**WHITE LABEL DOMAIN API */
export const GET_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/getAll`;
export const POST_CREATE_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/add`;
export const PUT_EDIT_WHITE_LABEL_DOMAIN = `${SERVICES.WHITE_LABEL_DOMAIN}/update`;
/**WHITE LABEL DOMAIN API */
