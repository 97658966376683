import axios from "axios";
import {
  DOWNLOAD_NFC,
  NFC_LIST,
  NFC__LIST_STATUS_UPDATE,
  ORDER_STATUS_LIST,
} from "../../constant/API constant";

export const NFCList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${NFC_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const OrderStatusList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORDER_STATUS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const UpdateStatusList = (formData, nfcOrderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${NFC__LIST_STATUS_UPDATE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { nfcOrderId },
    }
  );
};

export const DownloadNfcQr = (nfcId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DOWNLOAD_NFC}`, {
    headers: { Authorization: jwt_token, "Content-Type": "image/png" },
    params: { nfcId: nfcId },
    responseType: "blob",
  });
};
