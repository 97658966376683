import moment from "moment";
import React, { useContext } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { Download, Edit, Eye } from "react-feather";
import { MuiTabelStyle, NFC_LIST, USER_ROLE } from "../../constant";
import NfcListContext from "../../context/nfcListContext/NfcListContext";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DataNotFound from "../../DataNoteFound/DataNotFound";

const NfcListItem = (props) => {
  const {
    openModel,
    sorting,
    handleStatusChange,
    page,
    record,
    setPageSize,
    setPage,
    pageSize,
    downloadQrIcon,
  } = props;

  const { nfcOrder, setSelectStatus } = useContext(NfcListContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 220,
      field: "orgName",
      headerName: "ORGANIZATION NAME",
      renderCell: (params) => params.row.nfcOrder.orgDetail.orgName,
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "title",
      headerName: "TITLE",
      renderCell: (params) => params.row.nfcOrder.title,
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "frontImg",
      sortable: false,
      headerName: "IMAGE",
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={params.row.nfcOrder.frontImg}
          alt="photo"
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "hwId",
      sortable: false,
      headerName: "LINK",
      renderCell: (params) => (
        <a
          href={"https://bytenfc.com/" + params.row.hwId}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Link
        </a>
      ),
    },

    {
      flex: 0.1,
      minWidth: 120,
      field: "isSame",
      headerName: "SAME",
      renderCell: (params) =>
        params.row.nfcOrder.isSame === false ? "No" : "Yes",
    },
    USER_ROLE.ADMIN === +localStorage.getItem("roleId")
      ? {
          flex: 0.1,
          minWidth: 200,
          field: "statusName",
          headerName: "STATUS",
          renderCell: (params) => (
            <button
              style={{
                border: "none",
                background: "none",
                color: "rgb(115, 102, 255)",
              }}
              onClick={() =>
                handleStatusChange(
                  setSelectStatus(true),
                  params.row.nfcOrder.orderStatusId,
                  params.row.nfcOrderId
                )
              }
            >
              {params.row.nfcOrder.orderStatus.statusName}{" "}
              <Edit
                style={{
                  width: "19px",
                  paddingTop: "10px",
                }}
              />
            </button>
          ),
        }
      : {
          flex: 0.1,
          minWidth: 200,
          field: "statusName",
          headerName: "STATUS",
          renderCell: (params) => params.row.nfcOrder.orderStatus.statusName,
        },
    {
      flex: 0.1,
      minWidth: 200,
      field: "name",
      headerName: "CREATED",
      renderCell: (params) => params.row.nfcOrder.usrDetail.name,
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "createdAt",
      headerName: "CREATED AT At",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:MM"),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      renderCell: (params) => (
        <>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() =>
              openModel(
                params.row.pngLink,
                params.row.nfcId,
                params.row.nfcOrder.title
              )
            }
          >
            <Eye style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() =>
              downloadQrIcon(
                params.row.nfcId,
                params.row.pngLink,
                params.row.nfcOrder.title
              )
            }
          >
            <Download style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h3>{NFC_LIST.NFCLIST}</h3>
            </CardHeader>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {nfcOrder.length && nfcOrder.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={nfcOrder}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.nfcId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NfcListItem;
