import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, CHANGE_PASSWORD, Email } from "../../constant";
import {
  Change_Email,
  Change_Pass,
} from "../../api/ChangePassAndEmailApi/ChnagePaaAndEmailApi";
import { Zoom, toast } from "react-toastify";

const OrganizationChangeEmail = (props) => {
  const { changeEmailmodal, setChangeEmailModal } = props;

  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData = {
      oldEmail: oldEmail,
      newEmail: newEmail,
    };
    try {
      const { data } = await Change_Email(formData);
      if (data.status === true) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setOldEmail("");
        setNewEmail("");
      } else {
        toast.error(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
      setChangeEmailModal(false);
    } catch (error) {}
  };
  return (
    <Modal
      size="lg"
      isOpen={changeEmailmodal}
      toggle={() => setChangeEmailModal(!changeEmailmodal)}
    >
      <ModalHeader toggle={() => setChangeEmailModal(!changeEmailmodal)}>
        {"Change EmailId"}
      </ModalHeader>
      <ModalBody>
        <Container fluid={true} className="p-0">
          <Row>
            <Col xs="12">
              <div className="img">
                <div>
                  <div className="login-main login-tab">
                    <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                      <h4>{"Email ID"}</h4>
                      <p>{"Enter Old Email and New Email"}</p>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{Email}</Label>
                        <Input
                          className="form-control"
                          type={"email"}
                          onChange={(e) => setOldEmail(e.target.value)}
                          defaultValue={oldEmail}
                          placeholder="Old Email"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"New Email"}</Label>
                        <Input
                          className="form-control"
                          type={"email"}
                          onChange={(e) => setNewEmail(e.target.value)}
                          defaultValue={newEmail}
                          placeholder="New Email"
                          required
                        />
                      </div>
                      <div className="login-btn mb-0">
                        <div className="ms-3">
                          <Label className="text-muted" for="checkbox1">
                            {"Please Enter Old Email and New Email"}
                          </Label>
                        </div>
                        <Button color="primary" type="submit" value="submit">
                          {BUTTON.DONE}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default OrganizationChangeEmail;
