import axios from "axios";
import {
  ADD_ORG,
  ADD_ORG_BALANCE,
  EDIT_ORG,
  FILE,
  ORG_DITAILS,
  ORG_ENABLE_DESABLE,
  ORG_LIST,
  ORG_WALLET,
} from "../../constant/API constant";

export const OrgList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Org_Details = (orgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_DITAILS}`, {
    headers: { Authorization: jwt_token },
    params: { orgId },
  });
};

export const Edit_Org = (formData, orgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${EDIT_ORG}`, formData, {
    headers: { Authorization: jwt_token },
    params: { orgId },
  });
};

export const Add_Org = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${ADD_ORG}`, formData, {
    headers: { Authorization: jwt_token },
  });
};

export const Add_Org_Balance = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ORG_BALANCE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Org_Enable_Desable = (formData, orgId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_ENABLE_DESABLE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orgId },
    }
  );
};

export const Org_Wallet = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_WALLET}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const FILE_API = (formData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${FILE}`, formData);
};
