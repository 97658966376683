import React, { useContext } from "react";
import HashLoader from "react-spinners/HashLoader";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const LoaderResponse = () => {
  const { loaderShow } = useContext(LoaderContext);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    zIndex: "999",
    width: "100%",
    backgroundColor: "#b293bf75",
  };
  return (
    loaderShow === true && (
    <div className="loader" style={style}>
      {" "}
      <HashLoader
        color={"#883ea6"}
        loading={true}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
    )
  );
};

export default LoaderResponse;
