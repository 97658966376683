import React, { useContext } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { NFC_LIST } from "../../constant";
import NfcListContext from "../../context/nfcListContext/NfcListContext";

const Qrscanmodal = (props) => {
  const { downloadQr } = props;
  const { modal, setModal, qr, nfcId } = useContext(NfcListContext);
  return (
    <Modal size="sm" isOpen={modal} toggle={() => setModal(!modal)} centered>
      <ModalHeader toggle={() => setModal(!modal)}>
        {NFC_LIST.QR_SCAN}
      </ModalHeader>
      <ModalBody>
        <table>
          <tbody>
            <tr>
              <th style={{ display: "flex", justifyContent: "center" }}>
                <img src={qr} alt="" style={{ width: "16rem" }} />
              </th>
            </tr>
            <tr
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <th>
                <button
                  className="btn btn-primary"
                  onClick={() => downloadQr(nfcId)}
                >
                  {NFC_LIST.DOWNLOADE}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  );
};

export default Qrscanmodal;
