import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "reactstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import UserProfileItem from "./userProfileItem";
import { Profile_Api } from "../../api/User Profile Api/UserProfileApi";
import { USER_PROFILE } from "../../constant";

const UserProfile = () => {
  const [url, setUrl] = useState([]);

  const [orgName, setOrgName] = useState("");
  const [email, setEmail] = useState("");
  const [landmark, setLandmark] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [progress, setProgress] = useState(0);

  /**
   * profile details show function call
   */

  const profile = async () => {
    try {
      const { data } = await Profile_Api();
      if (data.status === 200) {
        // ORG DETAILS
        setUrl(data.payload.data.logo);
        setOrgName(data.payload.data.orgName);
        setEmail(data.payload.data.email);
        setLandmark(data.payload.data.landmark);
        setAdd1(data.payload.data.add1);
        setAdd2(data.payload.data.add2);
        setCity(data.payload.data.city);
        setState(data.payload.data.state);
        setDialCode(data.payload.data.dialCode);
        setPhone(data.payload.data.phone);
        // ADMIN DETAILS
        setName(data.payload.organizationAdminDetail.name);
      } else {
      }
    } catch (error) {
      toast.error(error.response);
    }
  };

  useEffect(() => {
    profile();
    setProgress(100);
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent={USER_PROFILE.PERENT} title={USER_PROFILE.TITLE} />
      <Container fluid={true}>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <UserProfileItem
          url={url}
          email={email}
          orgName={orgName}
          state={state}
          city={city}
          dialCode={dialCode}
          phone={phone}
          landmark={landmark}
          add1={add1}
          add2={add2}
          name={name}
        />
      </Container>
    </Fragment>
  );
};

export default UserProfile;
