import React, { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { ORGANIZATION } from "../../constant";
import WhiteLabelDomainContext from "../../context/WhiteLabelDomainContext/WhiteLabelDomainContext";

const WhiteLabelDomainDetailsModel = (props) => {
  const {
    ahoeDataModel,
    setahoeDataModel,
    url,
    orgName,
    email,
    dialCode,
    phone,
    add1,
    add2,
    landmark,
    city,
    state,
    name,
    note,
    nfcBel,
    qrBel,
  } = useContext(WhiteLabelDomainContext);
  return (
    <>
      <Modal
        size="lg"
        isOpen={ahoeDataModel}
        toggle={() => setahoeDataModel(!ahoeDataModel)}
      >
        <ModalHeader toggle={() => setahoeDataModel(!ahoeDataModel)}>
          View Organization
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody style={{ padding: "0" }}>
                  <Card>
                    <div className="table-responsive">
                      <Table>
                        <CardHeader
                          className="flex-shrink-1"
                          style={{ display: "flex", padding: "0" }}
                        >
                          <th>
                            <Media
                              body
                              alt=""
                              src={url}
                              style={{
                                width: "5rem",
                                height: "5rem",
                                borderRadius: "45rem",
                                border: "1px solid #f0f0f0",
                              }}
                              data-intro="This is Profile image"
                            />
                          </th>
                          <h5 style={{ paddingTop: "33px" }}>
                            {ORGANIZATION.ORG_DETAILS}
                          </h5>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{ORGANIZATION.ORG_NAME}</th>
                            <td>{orgName}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.EMAIL}</th>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.CONTACT}</th>
                            <td>{`${dialCode} ${phone}`}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.ADDRESS}</th>
                            <td>{`${add1} , ${add2} , ${
                              landmark ? landmark : "--"
                            }`}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.CITY}</th>
                            <td>{city}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.STATE}</th>
                            <td>{state}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.NFCBEL}</th>
                            <td>{nfcBel}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.QRBEL}</th>
                            <td>{qrBel}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.NOTE}</th>
                            <td>{note ? note : "--"}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table>
                        <CardHeader>
                          <h5>{ORGANIZATION.ADMIN_DETAILS}</h5>
                        </CardHeader>
                        <tbody>
                          <tr>
                            <th scope="row">{ORGANIZATION.NAME}</th>
                            <td>{name}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.EMAIL}</th>
                            <td>{email}</td>
                          </tr>
                          <tr>
                            <th scope="row">{ORGANIZATION.CONTACT}</th>
                            <td>{`${dialCode} ${phone}`}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WhiteLabelDomainDetailsModel;
