import axios from "axios";
import {
  CREATE_NFC,
  NFC_ORDER,
  NFC_ORDER_LIST_DETAILS,
  NFC_ORDER_LIST_TITLE_UPDATE,
  NFC_REGENARATE,
} from "../../constant/API constant";

export const NfcOrderList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${NFC_ORDER}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Add_Nfc = (formData2) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${CREATE_NFC}`,
    formData2,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Nfc_Regenarate = (fromData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${NFC_REGENARATE}`,
    fromData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Nfc_Order_List_Details = (nfcOrderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${NFC_ORDER_LIST_DETAILS}`,
    { headers: { Authorization: jwt_token }, params: { nfcOrderId } }
  );
};

export const Nfc_Order_List_Title_Update = (nfcOrderId, formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${NFC_ORDER_LIST_TITLE_UPDATE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { nfcOrderId },
    }
  );
};
