import axios from "axios";
import {
  CREATE_QR,
  OR_ORDER_LIST_TITLE_UPDATE,
  QR_LIST,
  QR_ORDER_LIST_DETAILS,
  QR_REGUNRATE,
} from "../../constant/API constant";

export const OrderLists = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${QR_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const Create_Qr = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(`${process.env.REACT_APP_API_URL}${CREATE_QR}`, formData, {
    headers: { Authorization: jwt_token },
  });
};

export const Regunrate_Qr = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${QR_REGUNRATE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const Qr_Order_List_Details = (qrOrderId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${QR_ORDER_LIST_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { qrOrderId },
  });
};

export const Qe_Order_List_Title_Update = (qrOrderId, formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${OR_ORDER_LIST_TITLE_UPDATE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { qrOrderId },
    }
  );
};
