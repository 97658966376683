import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Download } from "react-feather";
import { toast, Zoom } from "react-toastify";
import { Card, CardHeader, Col, Form, Input, Row, Table } from "reactstrap";
import { AllQrList, DownloadQr } from "../../api/QR List All Api/QrListAllApi";
import OrgContext from "../../context/OrgContext/OrgContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";

const GenratedQrAndNfc = () => {
  const [formvalue, setformvalue] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [filter, setFilter] = useState("");

  const { orgId } = useContext(OrgContext);

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        orgId: orgId || localStorage.getItem("orgIdGenQr"),
      };
      if (filter.length) {
        paramsObj["search"] = { title: filter };
      }
      const { data } = await AllQrList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
  }, [orgId, page, pageSize, filter]);

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 130,
      field: "title",
      headerName: "TITLE",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
      renderCell: (params) => params.row.qrOrder.title,
    },
    {
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      disableColumnMenu: true,
      field: "hwId",
      headerName: "LINK",
      renderCell: (params) => (
        <a
          // href={"https://bytenfc.com/" + params.row.hwId}
          href={
            params.row.qrOrder.orgDetail.qrDomain
              ? "https://" +
                params.row.qrOrder.orgDetail.qrDomain +
                "." +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
              : "https://" +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          View Link
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "name",
      headerName: "CREATED BY",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
      renderCell: (params) => params.row.qrOrder.usrDetail.name,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "createdAt",
      headerName: "CREATED AT",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {/* <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            // onClick={() =>
            //   openModel(
            //     params.row.url,
            //     params.row.qrId,
            //     params.row.qrOrder.title
            //   )
            // }
          >
            <Eye style={{ width: "19px" }} />
          </button> */}
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              downloadQrIcon(params.row.qrId, params.row.qrOrder.title)
            }
          >
            <Download style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  const downloadQrIcon = async (qrId, title) => {
    await DownloadQr(qrId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        console.log("error>>", error);
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h5>{"Qr Order List"}</h5>
          </CardHeader>
          <Table>
            <thead>
              <tr>
                <th>
                  <Row>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Input
                          className="form-control-plaintext"
                          type="text"
                          placeholder="Generated Qr Search ..."
                          value={filter}
                          onInput={(e) => setFilter(e.target.value)}
                          style={{
                            border: "1px solid #ecf3fa",
                            borderRadius: "8px",
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
          </Table>
          <Box
            sx={{
              "& .even": {
                backgroundColor: "#f1f1f1",
              },
              "& .odd": {
                backgroundColor: "white",
              },
            }}
          >
            {formvalue.length ? (
              <DataGrid
                autoHeight
                pagination
                rows={formvalue}
                rowCount={record}
                pageSize={pageSize}
                getRowId={(row) => row.qrId}
                columns={defaultColumns}
                page={page - 1}
                rowsPerPageOptions={[7, 10, 25, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                checkboxSelection={false}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            ) : (
              <DataNotFound />
            )}
          </Box>
        </Card>
      </Col>
    </Row>
  );
};

export default GenratedQrAndNfc;
