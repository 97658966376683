import React, { useContext } from "react";
import { Card, CardHeader, Col, Form, Input, Row, Table } from "reactstrap";
import { Download, Eye } from "react-feather";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import moment from "moment";
import { MuiTabelStyle, QR_LIST, WALLET_LIST } from "../../constant";
import WalletContext from "../../context/wallterContext/WalletContext";

const WalletItem = (props) => {
  const { sorting, setPage, setPageSize, pageSize, page, record } = props;
  const { walletData, setFilter, filter } = useContext(WalletContext);

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 130,
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => params.row.amount,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "type",
      headerName: "Type",
      renderCell: (params) =>
        params.row.type === 1 ? (
          <p style={{ color: "green" }}>Credit</p>
        ) : (
          <p style={{ color: "red" }}>Debit</p>
        ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "image",
      headerName: "Image",
      renderCell: (params) =>
        params.row.image ? (
          <a href={params.row.image} target="_blank">
            Image
          </a>
        ) : (
          "-"
        ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "note",
      headerName: "Note",
      renderCell: (params) => params.row.notes,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "date",
      headerName: "Date",
      renderCell: (params) => moment(params.row.data).format("DD-MM-YYYY"),
    },
  ];
  let amount = 0;
  for (const iterator of walletData) {
    amount += iterator.amount;
  }
  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h5>{`${WALLET_LIST.PERENT} : ${amount}`}</h5>
            </CardHeader>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {walletData.length && walletData.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={walletData}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.walletId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[2, 7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WalletItem;
