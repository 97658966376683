import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext } from "react";
import { Card, CardHeader, Col, Form, Input, Row, Table } from "reactstrap";
import { MuiTabelStyle, USERS } from "../../constant";
import UserContext from "../../context/userContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";

const UsersItem = (props) => {
  const { sorting, setPage, setPageSize, pageSize, page, record } = props;

  const { data, setFilter, filter } = useContext(UserContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "orgName",
      headerName: "ORGANIZATION NAME",
      renderCell: (params) => (params.row.org ? params.row.org.orgName : "--"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "name",
      headerName: "NAME",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "phone",
      headerName: "PHONE",
      renderCell: (params) =>
        params.row.phone ? (
          <>
            {" "}
            <span>{params.row.dialCode}</span> <span> </span>{" "}
            <span>{params.row.phone}</span>{" "}
          </>
        ) : (
          "--"
        ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "roleId",
      headerName: "ROLE",
      renderCell: (params) => (params.row.roleId === 2 ? "Seller" : "Admin"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        params.row.createdAt
          ? moment(params.row.createdAt).format("DD MMM, YYYY HH:mm")
          : "--",
    },
  ];

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <h3>{USERS.PERENT}</h3>
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Row>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="User Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </th>
                </tr>
              </thead>
            </Table>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {data.length && data.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={data}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.userId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UsersItem;
