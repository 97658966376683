import * as React from "react";
import { useState } from "react";
import OrderMasterContext from "./OrderMasterContext";

const OrderMasterState = (props) => {
  const [orderValue, setorderValue] = useState([]); //API -->

  const [statusName, setStatusName] = useState("");
  const [orderStatusId, setorderStatusID] = useState("");

  const [modal, setModal] = useState(false);

  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState(); //SORTING -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [totalRecord, setTotalRecord] = useState();

  const [editIndex, seteditIndex] = useState(-1); //EDIT -->
  const [progress, setProgress] = useState(0);
  const [toggleSubmit, setToggleSubmit] = useState(false);

  const [isHovering1, setIsHovering1] = useState(false);

  return (
    <>
      <OrderMasterContext.Provider
        value={{
          orderValue,
          setorderValue,
          statusName,
          setStatusName,
          orderStatusId,
          setorderStatusID,
          modal,
          setModal,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          isHovering,
          setIsHovering,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          editIndex,
          seteditIndex,
          progress,
          setProgress,
          toggleSubmit,
          setToggleSubmit,
          isHovering1,
          setIsHovering1,
        }}
      >
        {props.children}
      </OrderMasterContext.Provider>
    </>
  );
};

export default OrderMasterState;
