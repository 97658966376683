import axios from "axios";
import {
  ADD_ORDER_STATUS,
  EDIT_ORDER_STATUS,
  ORDER_STATUS_DETAILS,
  ORDER_STATUS_LIST,
} from "../../constant/API constant";

export const OrderStatusList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORDER_STATUS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const OrderStatusDetails = (orderStatusId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORDER_STATUS_DETAILS}`, {
    headers: { Authorization: jwt_token },
    params: { orderStatusId },
  });
};

export const OrderStatusEdit = (formData, orderStatusId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${EDIT_ORDER_STATUS}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orderStatusId },
    }
  );
};

export const OrderStatusAdd = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ADD_ORDER_STATUS}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
