import * as React from "react";

import { useState } from "react";
import WalletContext from "./WalletContext";

const WalletState = (props) => {
  const [walletData, setWalletData] = useState([]);

  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->

  const [modal, setModal] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <>
      <WalletContext.Provider
        value={{
          walletData,
          setWalletData,
          setTotalRecord,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          modal,
          setModal,
          progress,
          setProgress,
        }}
      >
        {props.children}
      </WalletContext.Provider>
    </>
  );
};

export default WalletState;
