import axios from "axios";
import { USER_LIST } from "../../constant/API constant";

export const UserList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${USER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};
