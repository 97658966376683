import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Container } from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import NfcListItem from "./nfcListItem";
import Qrscanmodal from "./qrscanmodal";
import {
  DownloadNfcQr,
  NFCList,
  OrderStatusList,
  UpdateStatusList,
} from "../../api/Nfc List Api/NfcListApi";
import EditStatusModal from "./editStatusModal";
import NfcListContext from "../../context/nfcListContext/NfcListContext";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { NFC_LIST } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { tableId } from "../../utils/helper";

const NfcList = () => {
  const {
    setNfcOrder,
    setQr,
    orderStatusId,
    setOrderStatusId,
    nfcOrderId,
    setNfcOrderId,
    setNfcId,
    nfcTitle,
    setNfcTitle,
    setModal,
    setorderValue,
    setSelectStatus,
    selectStatus,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    progress,
    setProgress,
    nfcOrder,
  } = useContext(NfcListContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * get nfc list api call function
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await NFCList(paramsObj);
      if (data.status === 200) {
        setNfcOrder(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setNfcOrder([]);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy]);

  tableId(page, pageSize, nfcOrder);

  /**
   * Emplty feild function call
   */

  const emptyFeild = () => {
    setNfcOrderId("");
    setOrderStatusId("");
    setLoaderShow(false);
  };

  /**
   * Fill feild function call
   */
  const fillFeild = () => {
    setNfcOrderId(nfcOrderId);
    setOrderStatusId(orderStatusId);
    setLoaderShow(false);
  };

  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * Show qr popup function call
   */

  const openModel = async (url, nfcId, title) => {
    setNfcTitle(title);
    setNfcId(nfcId);
    setQr(url);
    setModal(true);
  };

  /**
   * Downloade qr function
   */

  const downloadQr = async (nfcId) => {
    await DownloadNfcQr(nfcId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${nfcTitle}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
        setModal(false);
      })
      .catch((error) => {
        console.log("error>>", error);
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  /**
   * Downloade qr function
   */
  const downloadQrIcon = async (nfcId, link, title) => {
    await DownloadNfcQr(nfcId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
        setModal(false);
      })
      .catch((error) => {
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  /**
   * Status chage function call
   */

  const handleStatusChange = async (a, orderStatusId, nfcOrderId) => {
    setNfcOrderId(nfcOrderId);
    setOrderStatusId(orderStatusId);
    try {
      let paramsObj = {
        showAll: true,
      };
      const { data } = await OrderStatusList(paramsObj);
      if (data.status === 200) {
        setorderValue(data.payload.data);
        emptyFeild();
      } else {
        fillFeild();
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    handleStatusChange();
  }, []);

  /**
   * submit form function call
   */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    let formData;
    formData = { orderStatusId: orderStatusId };
    alert("Are You Sure?");
    try {
      const { data } = await UpdateStatusList(formData, nfcOrderId);
      if (data.status === 200) {
        setSelectStatus(false);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
        setLoaderShow(false);
      } else {
        setNfcOrder([]);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      toast.error(error.response.data.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * status model close function
   */

  const statusModelClose = () => {
    emptyFeild();
    setSelectStatus(!selectStatus);
  };
  return (
    <>
      <Fragment>
        <Breadcrumb parent={NFC_LIST.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <EditStatusModal
            handleSubmit={handleSubmit}
            statusModelClose={statusModelClose}
          />
          <Qrscanmodal downloadQr={downloadQr} />
          <NfcListItem
            openModel={openModel}
            sorting={sorting}
            handleStatusChange={handleStatusChange}
            pageSize={pageSize}
            page={page}
            record={record}
            setPageSize={setPageSize}
            setPage={setPage}
            downloadQrIcon={downloadQrIcon}
          />
        </Container>
      </Fragment>
    </>
  );
};

export default NfcList;
