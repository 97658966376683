import React, { useContext } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { Edit, Plus } from "react-feather";
import PackagesContext from "../../context/PackagesContext/PackagesContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { MuiTabelStyle, PACKAGES } from "../../constant";

const PackagesItem = (props) => {
  const {
    moment,
    oprenModel,
    editHandle,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    sorting,
  } = props;

  const { packageValue, setModal, setToggleSubmit } =
    useContext(PackagesContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 230,
      field: "orgName",
      headerName: "ORGANIZATION NAME",
      renderCell: (params) =>
        params.row.orgDetail ? params.row.orgDetail.orgName : "-",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "name",
      headerName: "NAME",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "price",
      headerName: "PRICE",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "benefit",
      headerName: "BENEFIT",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "gst",
      headerName: "GST",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "qty",
      headerName: "QTY",
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "type",
      headerName: "TYPE",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      sortable: false,
      field: "actions",
      headerName: "ACTIONS",
      renderCell: (params) => (
        <button
          className="_edit"
          type="button"
          style={{
            background: "none",
            textDecoration: "none",
            border: "none",
            // color: "#51bb25",
            color: "#7366ff",
          }}
          onClick={() => editHandle(params.row.packageId)}
        >
          <Edit style={{ width: "19px" }} />
        </button>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{PACKAGES.PERENT}</h3>
              <button
                type="button"
                style={{ border: "none", background: "none" }}
                onClick={() =>
                  oprenModel(setModal(true), setToggleSubmit(true))
                }
              >
                <div
                  style={{
                    width: "50px",
                    background: "#7366ff",
                    borderRadius: "19rem",
                    color: "white",
                  }}
                >
                  <h1>
                    <Plus />
                  </h1>
                </div>
              </button>
            </CardHeader>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {packageValue.length && packageValue.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={packageValue}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.packageId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PackagesItem;
