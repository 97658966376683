import React from "react";
import { Row, Col, Card, Media, CardHeader } from "reactstrap";
import { USER_PROFILE } from "../../constant";

const UserProfileItem = (props) => {
  const {
    url,
    email,
    orgName,
    state,
    city,
    dialCode,
    phone,
    landmark,
    add1,
    add2,
    name,
  } = props;
  return (
    <div className="user-profile">
      <Row>
        <Col sm="12">
          <Card className="card hovercard text-center">
            <CardHeader className=""></CardHeader>
            <div className="user-image">
              <div className="avatar">
                <Media
                  body
                  alt=""
                  src={url}
                  data-intro="This is Profile image"
                  style={{ border: "1px solid " }}
                />
              </div>
            </div>
            <div className="info">
              <Row>
                <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                  <Row>
                    <Col md="4">
                      <div className="ttl-info text-start">
                        <h6>
                          <i className="fa fa-envelope me-2"></i>{" "}
                          {USER_PROFILE.EMAIL}
                        </h6>
                        <span>{email}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>
                          <i className="icofont icofont-boy"></i>{" "}
                          {USER_PROFILE.NAME}
                        </h6>
                        <span>{orgName}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>
                          <i className="fa fa-bank"></i>   {USER_PROFILE.STATE}
                        </h6>
                        <span>{state}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="6" lg="4" className="order-sm-0 order-xl-1">
                  <div className="user-designation">
                    <h1 className="title">{USER_PROFILE.ORG_DETAILS}</h1>
                  </div>
                </Col>
                <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                  <Row>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>
                          <i className="icofont icofont-building-alt"></i>
                             {USER_PROFILE.CITY}
                        </h6>
                        <span>{city}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-xs-mt">
                        <h6>
                          <i className="fa fa-phone"></i>   
                          {USER_PROFILE.CONTACT_US}
                        </h6>
                        <span>{`${dialCode}${phone}`}</span>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>
                          <i className="fa fa-location-arrow"></i>   
                          {USER_PROFILE.LOCATION}
                        </h6>
                        <span>{`${
                          landmark ? landmark : "--"
                        },${add1},${add2}`}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start">
                        <h6>
                          <i className="fa fa-envelope me-2"></i>{" "}
                          {USER_PROFILE.EMAIL}
                        </h6>
                        <span>{email}</span>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-sm-mb-0">
                        <h6>
                          <i className="icofont icofont-boy"></i>{" "}
                          {USER_PROFILE.NAME}
                        </h6>
                        <span>{name}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
                  <div className="user-designation">
                    <h1 className="title">{USER_PROFILE.ADMIN_DETAILS}</h1>
                  </div>
                </Col>
                <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
                  <Row>
                    <Col md="6">
                      <div className="ttl-info text-start ttl-xs-mt">
                        <h6>
                          <i className="fa fa-phone"></i>   
                          {USER_PROFILE.CONTACT_US}
                        </h6>
                        <span>{`${dialCode}${phone}`}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default UserProfileItem;
