import * as React from "react";
import { useState } from "react";
import NfcOrderListContext from "./NfcOrderListContext";

const NfcOrderListState = (props) => {
  const [nfcorderValue, setNfcOrder] = useState([]);

  const [qty, setQty] = useState("");
  const [title, setTitle] = useState("");
  const [nfcOrderId, setNfcOrderID] = useState("");
  const [frontImg, setFrontImg] = useState([]);
  const [check, setCheck] = useState(false);

  const [editTitle, setEditTitle] = useState(false);
  const [nfcmodal, setNfcModal] = useState(false); //ADD DATA MODAL

  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->

  const [progress, setProgress] = useState(0);

  return (
    <>
      <NfcOrderListContext.Provider
        value={{
          nfcorderValue,
          setNfcOrder,
          qty,
          setQty,
          title,
          setTitle,
          nfcOrderId,
          setNfcOrderID,
          frontImg,
          setFrontImg,
          check,
          setCheck,
          editTitle,
          setEditTitle,
          nfcmodal,
          setNfcModal,
          isHovering,
          setIsHovering,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          progress,
          setProgress,
        }}
      >
        {props.children}
      </NfcOrderListContext.Provider>
    </>
  );
};

export default NfcOrderListState;
